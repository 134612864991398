<template>
  <div>
    <v-layout wrap justify-center fill-height>
      <v-flex xs12 sm12 md12 lg2 hidden-md-and-down>
        <v-navigation-drawer
          class="fixedSidebar"
          v-model="sideNav"
          clipped
          width="100%"
          height="100vh"
          permanent
          color="#202020"
        >
          <v-layout wrap>
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense v-for="main in appMenu" :key="main._id">
                <v-layout wrap>
                  <v-flex xs12 py-2>
                    <v-list-group
                      v-if="main._id !== '60e7e737933dd31d84ee3fff'"
                      no-action
                      :value="false"
                      :active-class="`white--text`"
                    >
                      <template v-slot:activator>
                        <v-list-item-title
                          style="
                            font-size: 14px;
                            font-weight: bolder;
                            letter-spacing: 3px;
                            cursor: pointer;
                            font-family: poppinsthin;
                          "
                          >{{ main.name }}
                        </v-list-item-title>
                      </template>
                      <v-flex xs12 py-2 px-4>
                        <div style="border-bottom: 1px solid #ffffff25"></div>
                      </v-flex>
                      <template v-for="sub in main.subMenu">
                        <v-flex xs12 text-left pl-6 :key="sub._id">
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <router-link :to="sub.route">
                                <span
                                  :style="
                                    $route.path == sub.route
                                      ? {
                                          'font-family': 'poppinsbold',
                                          color: '#68D389',
                                        }
                                      : {
                                          'font-family': 'poppinsthin',
                                        }
                                  "
                                  style="
                                    font-size: 13px;
                                    font-weight: bolder;
                                    color: #ffffff;
                                    letter-spacing: 3px;
                                    cursor: pointer;
                                  "
                                >
                                  {{ sub.name }}
                                </span>
                              </router-link>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 py-2 px-4 pl-6 :key="sub._d">
                          <div style="border-bottom: 1px solid #ffffff25"></div>
                        </v-flex>
                      </template>
                    </v-list-group>
                    <!-- <v-list-group v-else> -->
                    <v-list dark dense v-else>
                      <template v-for="(item, i) in admin">
                        <v-flex xs12 pl-4 align-self-center text-left :key="i">
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <router-link :to="item.route">
                                <span
                                  :style="
                                    $route.path == item.route
                                      ? {
                                          'font-family': 'poppinsbold',
                                        }
                                      : {
                                          'font-family': 'poppinsthin',
                                        }
                                  "
                                  style="
                                    font-size: 13px;
                                    color: #ffffff;
                                    letter-spacing: 3px;
                                    cursor: pointer;
                                  "
                                >
                                  {{ item.name }}
                                </span>
                              </router-link>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <!-- <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex> -->
                      </template>
                    </v-list>
                  </v-flex>
                </v-layout>
                <!-- </v-list-group> -->
              </v-list>
            </v-flex>
          </v-layout>

          <!-- <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <v-list-group
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 3px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >DASHBOARD</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in dash">
                    <v-flex xs12 text-left pl-4 :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout>

          <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <v-list-group
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 2.5px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >REPORTS</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in reports">
                    <v-flex xs12 pl-4 align-self-center text-left :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout>

          <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <template v-for="(item, i) in admin">
                  <v-flex xs12 pl-4 align-self-center text-left :key="i">
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <router-link :to="item.route">
                          <span
                            :style="
                              $route.path == item.route
                                ? {
                                    'font-family': 'poppinsbold',
                                  }
                                : {
                                    'font-family': 'poppinsthin',
                                  }
                            "
                            style="
                              font-size: 13px;
                              color: #ffffff;
                              letter-spacing: 3px;
                              cursor: pointer;
                            "
                          >
                            {{ item.name }}
                          </span>
                        </router-link>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </template>
              </v-list>
            </v-flex>
          </v-layout>

          <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <v-list-group
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 2.5px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >PROGRAMS</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in programs">
                    <v-flex xs12 pl-4 align-self-center text-left :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout>

          <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <v-list-group
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 2.5px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >ACCOMODATIONS</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in accommodations">
                    <v-flex xs12 pl-4 align-self-center text-left :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <v-list-group
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 2.5px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >PRODUCTS</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in products">
                    <v-flex xs12 pl-4 align-self-center text-left :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout>

          <v-layout wrap v-if="appType !== 'productadmin'">
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <v-list-group
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 2.5px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >WEBSITE CONTENT</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in website">
                    <v-flex xs12 pl-4 align-self-center text-left :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="i">
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout> -->
          <!-- <v-layout wrap justify-center>
            <template v-for="(item, i) in navItems">
              <v-flex
                xs12
                :key="i"
                text-center
                py-2
                text-uppercase
                align-self-center
              >
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <router-link :to="item.route">
                      <span
                        :style="
                          $route.path == item.route
                            ? {
                                'font-family': 'poppinsbold',
                              }
                            : {
                                'font-family': 'poppinsthin',
                              }
                        "
                        style="
                          font-size: 13px;
                          color: #ffffff;
                          letter-spacing: 3px;
                          cursor: pointer;
                        "
                      >
                        {{ item.name }}
                      </span>
                    </router-link>
                  </v-flex>

                  <v-flex xs12 pt-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </template>
          </v-layout> -->
          <!-- <v-layout wrap pb-10 v-if="appType !== 'productadmin'">
            <v-flex xs12 text-center py-2 text-uppercase align-self-center>
              <v-list dark dense>
                <v-list-group
                  no-action
                  :value="false"
                  :active-class="`white--text`"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 3px;
                        cursor: pointer;
                        font-family: poppinsthin;
                      "
                      >STATIC</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in stat">
                    <v-flex xs12 text-center :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.to">
                            <span
                              :style="
                                $route.path == item.to
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinsthin',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: #ffffff;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                        <v-flex xs12 py-2 px-4>
                          <div style="border-bottom: 1px solid #ffffff25"></div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout> -->
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md12 lg10 pt-12 pt-lg-0>
        <v-layout
          wrap
          justify-center
          :style="
            $route.name == 'Dashboard'
              ? 'background-color: #ffffff'
              : 'background-color: #f6fff9'
          "
        >
          <v-flex xs12 pa-4>
            <v-card
              min-height="100vh"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap justify-center>
                <v-flex xs12 pa-4>
                  <router-view :key="$route.fullPath"></router-view>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import template from '../../template.vue';
import store from "./../../store";
export default {
  // components: { template },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      sideNav: true,
      dash: [
        { name: "DASHBOARD", route: "/Admin" },

        { name: "ANALYTICS", route: "/Analytics" },
        { name: "HOLIDAYS", route: "/Admin/holidays" },
      ],
      reports: [
        { name: "Booking List", route: "/Admin/bookingList/dateWisePrograms" },
        {
          name: "Reservation List",
          route: "/Admin/ReservationList/dateWisePrograms",
        },
        { name: "Cancelled Booking", route: "/Admin/cancelledList" },
        { name: "Program Report", route: "/Admin/bookingreport" },
        {
          name: "Reports",
          route: "/Transactions",
        },
        { name: "Purchase Report", route: "/Admin/purchaseReports" },
        { name: "Guest List", route: "/Admin/guestList" },
        { name: "User List", route: "/Admin/userList" },
        { name: "User Report", route: "/Admin/UserReport" },
        { name: "Consolidated User", route: "/Admin/UserConsolidate" },
      ],

      admin: [{ name: "ADMINS", route: "/Admins" }],
      programs: [
        { name: "PROGRAMS", route: "/Admin/Programs" },
        { name: "ADD PROGRAM", route: "/Admin/Programs/Add" },
        { name: "CATEGORY", route: "/Admin/category" },
      ],
      accommodations: [
        { name: "ACCOMODATION", route: "/Admin/cottage" },
        { name: "ADD ACCOMODATION", route: "/Admin/Cottages/Add" },
      ],
      products: [
        { name: "PRODUCTS", route: "/products" },
        { name: "ADD PRODUCT", route: "/addProduct" },
      ],
      website: [
        { name: "Notification", route: "/Admin/notifications" },
        { name: "Home Slider", route: "/homeSlider" },
        { name: "Static", route: "/viewStatic" },
      ],
      adminNavItems: [
        { name: "DASHBOARD", route: "/Admin" },
        { name: "ANALYTICS", route: "/Analytics" },
        { name: "ADMINS", route: "/Admins" },
        // { name: "ADD OFFICES", route: "/Admin/AddOffices" },
        { name: "PROGRAMS", route: "/Admin/Programs" },
        { name: "PRODUCTS", route: "/products" },
        { name: "ADD PROGRAM", route: "/Admin/Programs/Add" },
        { name: "ACCOMODATION", route: "/Admin/cottage" },
        { name: "ADD ACCOMODATION", route: "/Admin/Cottages/Add" },
        { name: "ADD PRODUCT", route: "/addProduct" },
        { name: "HOLIDAYS", route: "/Admin/holidays" },
        { name: "CATEGORY", route: "/Admin/category" },
        { name: "Get Involved List", route: "/Admin/getInvolved" },
        { name: "Get Involved", route: "/getInvolvedStatic" },
        { name: "Booking List", route: "/Admin/bookingList/dateWisePrograms" },
        {
          name: "Reservation List",
          route: "/Admin/ReservationList/dateWisePrograms",
        },
        { name: "Cancelled Booking", route: "/Admin/cancelledList" },
        { name: "Guest List", route: "/Admin/guestList" },
        { name: "User List", route: "/Admin/userList" },
        { name: "User Report", route: "/Admin/UserReport" },
        { name: "Consolidated User", route: "/Admin/UserConsolidate" },
        { name: "Program Report", route: "/Admin/bookingreport" },
        {
          name: "Reports",
          route: "/Transactions",
        },
        { name: "Purchase Report", route: "/Admin/purchaseReports" },
        {
          name: "Learn About The Reserve",
          route: "/learnAboutTheReserve",
        },

        { name: "Notification", route: "/Admin/notifications" },
        { name: "Home Slider", route: "/homeSlider" },
      ],

      subadminNavItems: [
        { name: "DASHBOARD", route: "/Admin" },
        { name: "ANALYTICS", route: "/Analytics" },
        // { name: "ADMINS", route: "/Admins" },
        // { name: "ADD OFFICES", route: "/Admin/AddOffices" },
        { name: "PROGRAMS", route: "/Admin/Programs" },
        { name: "PRODUCTS", route: "/products" },
        { name: "ADD PROGRAM", route: "/Admin/Programs/Add" },
        { name: "ACCOMODATION", route: "/Admin/cottage" },
        { name: "ADD ACCOMODATION", route: "/Admin/Cottages/Add" },
        { name: "ADD PRODUCT", route: "/addProduct" },
        { name: "HOLIDAYS", route: "/Admin/holidays" },
        { name: "CATEGORY", route: "/Admin/category" },
        { name: "Get Involved List", route: "/Admin/getInvolved" },
        { name: "Get Involved", route: "/getInvolvedStatic" },
        { name: "Booking List", route: "/Admin/bookingList/dateWisePrograms" },
        {
          name: "Reservation List",
          route: "/Admin/ReservationList/dateWisePrograms",
        },
        { name: "Cancelled Booking", route: "/Admin/cancelledList" },
        { name: "Guest List", route: "/Admin/guestList" },
        { name: "User List", route: "/Admin/userList" },
        { name: "User Report", route: "/Admin/UserReport" },
        { name: "Consolidated User", route: "/Admin/UserConsolidate" },
        { name: "Program Report", route: "/Admin/bookingreport" },
        {
          name: "Reports",
          route: "/Transactions",
        },
        { name: "Purchase Report", route: "/Admin/purchaseReports" },
        {
          name: "Learn About The Reserve",
          route: "/learnAboutTheReserve",
        },

        { name: "Notification", route: "/Admin/notifications" },
        { name: "Home Slider", route: "/homeSlider" },
      ],
      stat: [
        {
          name: "Page Contributor",
          to: "/Admin/staticComponent/Page Contributors",
        },
        {
          name: "Travel and Cancellation Policy",
          to: "/Admin/staticComponent/Travel and Cancellation Policy",
        },
        {
          name: "Privacy Policy",
          to: "/Admin/staticComponent/Privacy Policy",
        },

        {
          name: "Terms and Conditions",
          to: "/Admin/staticComponent/Terms and Conditions",
        },
        {
          name: "Indemnity Bond",
          to: "/Admin/staticComponent/Indemnity Bond",
        },
      ],
      userNavItems: [
        // { name: "DASHBOARD", route: "/Admin" },
        // { name: "ADMINS", route: "/Admins" },
        // { name: "ADD OFFICES", route: "/Admin/AddOffices" },
        // { name: "PROGRAMS", route: "/Admin/Programs" },
        { name: "PRODUCTS", route: "/products" },
        // { name: "ADD PROGRAM", route: "/Admin/Programs/Add" },
        // { name: "ACCOMODATION", route: "/Admin/cottage" },
        // { name: "ADD ACCOMODATION", route: "/Admin/Cottages/Add" },
        // { name: "ADD PRODUCT", route: "/addProduct" },
        // { name: "HOLIDAYS", route: "/Admin/holidays" },
        // { name: "CATEGORY", route: "/Admin/category" },
        // { name: "Get Involved List", route: "/Admin/getInvolved" },
        // { name: "Get Involved", route: "/getInvolvedStatic" },
        // { name: "Booking List", route: "/Admin/bookingList/dateWisePrograms" },
        // { name: "Cancelled Booking", route: "/Admin/cancelledList" },
        // { name: "Guest List", route: "/Admin/guestList" },
        // { name: "User List", route: "/Admin/userList" },
        // { name: "Program Report", route: "/Admin/bookingreport" },
        { name: "Purchase Report", route: "/Admin/purchaseReports" },
        // {
        //   name: "Learn About The Reserve",
        //   route: "/learnAboutTheReserve",
        // },

        // { name: "Notification", route: "/Admin/notifications" },
        // { name: "Home Slider", route: "/homeSlider" },
      ],
    };
  },
  computed: {
    appType() {
      return store.state.userType;
    },
    appMenu() {
      // console.log("menus", store.state.menus);
      return store.state.menus;
    },
    navItems() {
      if (store.state.userType == "admin") {
        return this.adminNavItems;
      } else if (store.state.userType == "subadmin") {
        return this.subadminNavItems;
      } else return this.userNavItems;
    },
    userData() {
      return store.state.userData;
    },
  },
  methods: {
    changeRoute(item) {
      if (this.$route.query.styl != item._id) {
        this.$router.push({
          path: "/Products",
          query: {
            styl: item._id,
            category: this.$route.query.category,
            subcategory: this.$route.query.subcategory,
            searchKey: this.$route.query.searchKey,
          },
        });
      } else {
        this.$router.push({
          path: "/Products",
          query: {
            styl: null,
            category: this.$route.query.category,
            subcategory: this.$route.query.subcategory,
            searchKey: this.$route.query.searchKey,
          },
        });
      }
    },
  },
};
</script>
<style>
@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
</style>